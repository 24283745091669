<template>
	<div class="modal-edit-payment-expire-container">
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			class="modal-edit-payment-expire"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<div class="payment-expire-fields d-flex">
					<BaseInputDate
						v-model="$v.expireDate.$model"
						:is-valid="!$v.expireDate.$error && isDateTimeValid"
						:invalid-feedback="expireDateErrorMessage"
						:min-date="minExpireDate"
						label="Date"
						placeholder="Select date"
						class="payment-expire-date"
						@input="resetError"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
					<BaseInputTime
						v-model="$v.expireTime.$model"
						:is-valid="!$v.expireTime.$error"
						:invalid-feedback="$t('global.error.required')"
						label="Time"
						class="payment-expire-time"
						@input="resetError"
					/>
				</div>
			</template>
			<template #footer>
				<CButton
					color="tertiary"
					class="transparent mr-3"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					color="primary"
					class="transparent"
					:disabled="isSubmitting"
					@click="handleSubmit"
				>
					{{ isSubmitting ? 'Submitting...' : 'Confirm' }}
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import { required } from 'vuelidate/lib/validators';
import { timeFormat, isDateTimeAfterNow } from '../assets/js/validators';

export default {
	name: 'ModalEditPaymentExpire',
	validations: {
		expireDate: {
			required,
		},
		expireTime: {
			required,
			timeFormat,
		},
	},
	components: {
		ModalHeaderWrapper,
	},
	props: {
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '-',
		},
	},
	data() {
		return {
			isShow: false,
			isDateTimeValid: true,
			expireDate: null,
			expireTime: null,
			minExpireDate: new Date(),
		};
	},
	computed: {
		expireDateErrorMessage() {
			if (!this.$v.expireDate.required) {
				return this.$t('global.error.required');
			}

			return this.$t('global.error.datetimeAfterNow');
		},
		isDateTimeAfterNowValid() {
			return isDateTimeAfterNow(this.expireDate, this.expireTime);
		},
	},
	methods: {
		open() {
			this.expireDate = null;
			this.expireTime = null;
			this.isDateTimeValid = true;
			this.isShow = true;
		},
		close() {
			this.$v.$reset();
			this.isShow = false;
		},
		resetError() {
			this.isDateTimeValid = true;
		},
		async handleSubmit() {
			this.$v.$touch();

			this.isDateTimeValid = this.isDateTimeAfterNowValid;

			if (!this.$v.$invalid && this.isDateTimeValid) {
				this.$emit('onConfirm', {
					expireDate: this.expireDate,
					expireTime: this.expireTime,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.payment-expire {
		// .payment-expire-fields
		&-fields {
			display: flex;
		}

		// .payment-expire-date
		&-date {
			width: rem(200);
		}

		// .payment-expire-time
		::v-deep &-time {
			margin-left: rem(12);

			.label {
				margin-top: 0;
			}
		}
	}
</style>
