<template>
	<div>
		<div class="cart-wrapper" :class="`table-type-${tableType}`">
			<BaseTable
				:fields="fields"
				:items="dataTable"
				class="table-custom"
			>
				<template #no-items-view>
					<div class="empty-table-element">
						<p class="subtitle">
							{{ $t("global.searchNotFound", { field: "orders" }) }}
						</p>
					</div>
				</template>
				<template #product="{item}">
					<div class="product-info" :class="{ 'product-freebie': item.type === ORDER_ITEM_TYPE.FREEBIE }">
						<img
							:src="item.thumbnail"
							class="thumbnail thumbnail-40"
							:alt="item.name"
						>
						<div class="description">
							<p class="mb-0">
								{{ item.name }}
							</p>
							<p class="color-black-45 mb-0">
								SKU: {{ item.sku }}
							</p>
							<p v-if="item.stockOptionNoticeText" class="color-alert mb-0">
								{{ item.stockOptionNoticeText }}
							</p>
							<CBadge v-if="item.type !== ORDER_ITEM_TYPE.NORMAL" class="badge badge-product-type mt-2">
								{{ ORDER_ITEM_TYPE_TITLE[item.type] }}
							</CBadge>
						</div>
					</div>
				</template>
				<template #priceSelling="{item}">
					{{ item.price }}
					<span v-if="item.isFlashSale" class="color-black-45 d-block">Flash sale</span>
				</template>
				<template #pricePreOrder="{item}">
					<div v-if="item">
						{{ item.price }} <span class="color-black-45 d-block">{{ item.type }}</span>
					</div>
					<div v-else>-</div>
				</template>
			</BaseTable>
		</div>
	</div>
</template>

<script>
import { ORDER_ITEM_TYPE, ORDER_ITEM_TYPE_TITLE } from '../enums/orders';
import { transformedOrderDetailProductTable } from '../assets/js/transform/order';

export default {
	name: 'OrderDetailProductTable',

	props: {
		items: {
			type: Array,
			default: () => [],
		},
		fields: {
			type: Array,
			default: () => [],
		},
		tableType: {
			type: String,
			default: null,
		},
		flashSales: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			ORDER_ITEM_TYPE,
			ORDER_ITEM_TYPE_TITLE,
		};
	},
	computed: {
		dataTable() {
			const products = [];

			this.items.forEach((item) => {
				const totalChildren = item.children.length;
				products.push({
					...transformedOrderDetailProductTable(item),
					rowClass: `row-product${totalChildren ? ' has-children' : ''}`,
				});

				if (totalChildren) {
					item.children.forEach((itemChildren, index) => {
						products.push({
							...transformedOrderDetailProductTable(itemChildren),
							rowClass: `row-freebie${(index + 1) === totalChildren ? ' row-freebie-last' : ''}`,
						});
					});
				}
			});

			return products;
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .cart-wrapper {
		.product-cel {
			.product-info {
				display: flex;

				.thumbnail {
					flex: 0 0 rem(40);

					border-radius: rem(4);
					border: rem(1) solid $color-gray-300;
				}

				.description {
					flex: 1;
					margin-left: rem(16);
				}
			}
		}

		.empty-table-element {
			margin: 0;
		}

		.table-custom {
			margin: 0;
			border-radius: rem(4);
			text-align: right;

			table {
				margin-bottom: 0;
			}

			thead > tr > th:first-child,
			tbody > tr > td:first-child {
				padding-left: rem(16);
			}

			thead > tr > th:last-child,
			tbody > tr > td:last-child {
				padding-right: rem(16);
			}

			thead {
				th {
					padding: rem(12) rem(4);
					background-color: $color-gray-100;
				}
			}

			tbody {
				td {
					padding: rem(12) rem(4);
					word-break: unset;
					vertical-align: top;
				}
			}

			.product {
				&-col, &-cel {
					text-align: left;
					word-break: break-word;
				}
			}

			.quantity {
				&-col, &-cel {
					text-align: center;
					word-break: break-word;
				}
			}
		}
	}

	::v-deep .table-type-product {
		.product-freebie {
			position: relative;
			padding-left: rem(16);

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				height: calc(100% + #{rem(16)});
				border-left: 4px solid $color-gray-300;
			}
		}

		.has-children,
		.row-freebie {
			td {
				border-bottom: none;
			}
		}

		.row-freebie + .row-freebie {
			td {
				padding-top: 0;
			}
		}

		.row-freebie + .row-product {
			td {
				border-top: 1px solid $color-gray-200;
			}
		}

		.row-freebie-last {
			.product-freebie {
				&::before {
					height: 100%;
				}
			}
		}
	}


	/**
	* Print
	*/
	@media print {
		::v-deep .product-table {
			border: none;
			margin-top: 0 !important;
		}
	}
</style>
