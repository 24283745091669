<template>
	<div>
		<BaseModalConfirm
			ref="modal-confirm-send-sms"
			title="Send SMS?"
			:description="smsDescription"
			@onConfirm="handleConfirmSendSms"
		/>
		<BaseModalLoading ref="modal-loading" modal-title="Sending" />
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import { ORDER_STATUS } from '../enums/orders';
import { PAYMENT_STATUS } from '../enums/payments';
import { sendOrderSmsAPI, sendOrderNotifyMobileAPI } from '../services/api/orders.api';

export default {
	name: 'OrderDetailSms',
	components: {
		BaseModalConfirm,
	},
	props: {
		order: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		// Order Status
		orderStatus() {
			return this.order?.orderStatus ?? null;
		},
		isPreparing() {
			return this.orderStatus === ORDER_STATUS.PREPARING;
		},
		isReadyToPickup() {
			return this.orderStatus === ORDER_STATUS.READY_TO_PICK_UP;
		},

		// Payment Status
		paymentStatus() {
			return this.order?.payment?.status ?? null;
		},
		isPaid() {
			return this.paymentStatus === PAYMENT_STATUS.PAID;
		},

		smsDescription() {
			if (this.isReadyToPickup) {
				return '"Order ready to pick up” SMS will send to customer immediately.';
			} else if (this.isPaid && this.isPreparing) {
				return '"Order confirmed" SMS will send to customer immediately.';
			}

			return null;
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),
		async handleConfirmSendSms() {
			const orderId = this.$route.params.id;

			this.$refs['modal-loading'].open();
			try {
				await sendOrderSmsAPI(orderId);
				await sendOrderNotifyMobileAPI(orderId);

				this.showToast({
					content: 'Your message has been sent.',
					header: this.$t('global.successMessageTitle'),
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: this.$t('global.errorMessageTitleSend'),
					type: 'danger',
				});
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
	},
};
</script>
