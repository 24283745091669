<template>
	<div :class="[status, 'wrapper px-3 pb-3 pt-0 ml-2']">
		<div class="title">
			{{ title }}
		</div>
		<div class="created-info mb-2">
			By {{ createdBy }} | {{ createdAt }}
		</div>
		<div
			v-if="message"
			ref="content"
			:class="[
				'content p-2',
				{
					hidden: isHidden,
					overflow: lineCounts > 5
				}
			]"
		>
			<span
				ref="message"
				class="message"
			>
				{{ message }}
			</span>
		</div>
		<div
			v-if="lineCounts > 5"
			class="see-more pb-2"
		>
			<div
				class="color-hypertext text-center cursor-pointer"
				@click="isHidden = !isHidden"
			>
				<span class="mr-2">{{ isHidden ? 'See more' : 'See less' }}</span>
				<FontAwesomeIcon
					:icon="['fas', isHidden ? 'chevron-down' : 'chevron-up']"
					size="sm"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OrderDetailHistory',
	props: {
		title: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: null,
			validator(value) {
				// The value must match one of these strings
				return ['success', 'info', 'danger'].indexOf(value) !== -1;
			},
		},
		message: {
			type: String,
			default: null,
		},
		createdBy: {
			type: String,
			default: null,
		},
		createdAt: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			lineCounts: 0,
			isHidden: false,
		};
	},
	mounted() {
		const LINE_HEIGHT = 16;
		if (this.$refs.message) {
			const divHeight = this.$refs.message.offsetHeight;
			this.lineCounts = Math.floor(divHeight / LINE_HEIGHT);
			if (this.lineCounts > 5) {
				this.isHidden = true;
			}
		}
	},
};
</script>

<style lang="scss" scoped>
	.wrapper {
		position: relative;
		background-image: linear-gradient($color-gray-300 60%, rgba(0, 0, 0, 0) 0%);
		background-position: left;
		background-size: 1px 10px;
		background-repeat: repeat-y;

		&::before {
			content: "";
			display: block;
			position: absolute;
			width: rem(13);
			height: rem(13);
			border-radius: rem(6);
			left: rem(-6);
			top: rem(3);
		}

		&.info::before {
			background: $color-info;
		}

		&.danger::before {
			background: $color-alert;
		}

		&.success::before {
			background: $color-success;
		}
	}

	.title {
		font-size: rem($font-size-body-2);
		color: $color-black-90;
		font-weight: $font-weight-semi-bold;
	}

	.created-info {
		color: $color-black-45;
		font-size: rem($font-size-caption);
		font-weight: normal;
	}

	.content {
		background-color: $color-gray-100;
		border-radius: rem(4);

		&.hidden {
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		&.overflow {
			border-radius: rem(4) rem(4) 0 0;
		}
	}

	.message {
		color: $color-black-45;
		font-size: rem(12);
		font-weight: normal;
		line-height: rem(16);
		white-space: pre-line;
	}

	.see-more {
		background-color: $color-gray-100;
		font-size: rem(12);
		border-radius: 0 0 rem(4) rem(4);
	}
</style>
