<template>
	<div class="order-flash-sale-container py-3">
		<div class="order-flash-sale mb-0">
			<h6 class="typo-body-2">Differential flash sale discount</h6>
			<p class="typo-body-2 color-black-45">Flash sale discount isn't including in the total price. This section is use for inform an admin to beware on the accounting process.</p>

			<div
				class="box can-interact"
				@click="showSummaryDetail()"
			>
				<div class="typo-body-2 mr-3">
					{{ name }}
				</div>
				<div class="text-nowrap flash-sale-discount">
					-{{ totalDiscount }}
				</div>
			</div>
		</div>
		<ModalOrderDetailSummary
			ref="modal-order-summary-detail"
			:fields="modalFields"
			:data="modalData"
			:total-amount="totalDiscount"
			class="modal-order-summary-detail"
			title="name"
			type="flashSale"
		/>
	</div>
</template>

<script>
import ModalOrderDetailSummary from '@/components/ModalOrderDetailSummary.vue';
import { ORDER_DETAIL_FLASH_SALE_TABLE_FIELDS } from '../enums/orders';

export default {
	name: 'OrderDetailFlashSale',

	components: {
		ModalOrderDetailSummary,
	},

	props: {
		name: {
			type: String,
			default: null,
		},
		totalDiscount: {
			type: String,
			default: null,
		},
		items: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			modalFields: ORDER_DETAIL_FLASH_SALE_TABLE_FIELDS,
		};
	},

	computed: {
		modalData() {
			return (this.items).map((item) => ({
				product: {
					name: item.product.name,
					thumbnail: item.product.thumbnail,
					sku: item.product.sku,
				},
				quantity: item.product.quantity,
				sellingPrice: item.sellingPrice,
				flashSalePrice: item.flashSalePrice,
				discount: item.discount,
			}));
		},
	},

	methods: {
		showSummaryDetail() {
			this.$refs['modal-order-summary-detail'].open();
		},
	},
};
</script>

<style lang="scss" scoped>
	.order-flash-sale {
		max-width: rem(350);
		margin: 0 rem(16) rem(24) auto;

		&::before {
			content: "";
			border-top: 1px solid $color-gray-300;
		}

		// order-flash-sale-container
		&-container {
			border-top: 1px solid $color-gray-300;
		}
	}

	.box {
		@include typo-body-2;

		display: flex;
		justify-content: space-between;
		background-color: $color-gray-100;
		padding: rem(16);

		&:first-of-type {
			border-top-left-radius: rem(4);
			border-top-right-radius: rem(4);
		}

		&:last-of-type {
			border-bottom-left-radius: rem(4);
			border-bottom-right-radius: rem(4);
			border-bottom: none;
		}

		&.can-interact {
			cursor: pointer;

			&:hover {
				background-color: $color-gray-200;
			}
		}
	}

	.flash-sale-discount {
		color: $color-gray-600;
	}

	::v-deep .modal-order-summary-detail {
		.modal-dialog {
			max-width: rem(840);
		}
	}
</style>