<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Cancel entire order"
					@onClose="close"
				/>
			</template>
			<template #body-wrapper>
				<CRow
					:gutters="false"
					class="px-3 pt-3"
				>
					<CCol>
						<BaseDropDown
							v-model="$v.selectedReason.$model"
							:options="cancelReasonOptions"
							:searchable="false"
							label="name"
							label-drop-down="Cancelation reason*"
							placeholder="Select cancelation a reason"
						/>
					</CCol>
				</CRow>
				<CRow
					:gutters="false"
					class="px-3 pb-3"
				>
					<CCol>
						<CTextarea
							v-model="reasonDetail"
							label="More detail"
							rows="2"
						/>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<CButton
					color="tertiary"
					class="mr-3"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					:disabled="!selectedReason"
					color="primary"
					@click="handleSubmit"
				>
					Confirm to cancel
				</CButton>
			</template>
		</CModal>
		<BaseModalConfirm
			ref="modal-confirm"
			class="modal-confirm-cancel-order"
			title="Confirm to cancel?"
			description="This action cannot undo. All items selected will be canceled and the email will send to customer immediately."
			@onConfirm="handleConfirm"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';

export default {
	name: 'ModalCancelOrder',
	components: {
		ModalHeaderWrapper,
		BaseModalConfirm,
	},
	validations: {
		selectedReason: {
			required,
		},
	},
	props: {
		orderId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			isShow: false,
			selectedReason: null,
			reasonDetail: '',
		};
	},
	computed: {
		...mapGetters({
			cancelReasonOptions: 'orders/cancelReasonOptions',
		}),
	},
	async created() {
		await this.getCancelOrder();
	},
	methods: {
		...mapActions({
			getCancelOrder: 'orders/getCancelOrder',
		}),
		resetState() {
			this.selectedReason = null;
			this.reasonDetail = '';
		},
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		handleSubmit() {
			this.$refs['modal-confirm'].open();
		},
		handleConfirm() {
			const params = {
				order_cancelation_reason_id: this.selectedReason.ReasonId,
				reason_detail: this.reasonDetail,
			};
			this.isShow = false;
			this.$emit('onConfirm', this.orderId, params);
		},
	},
};
</script>

<style lang="scss" scoped>
	.header {
		background-color: $color-gray-100;
	}

	.product-image {
		width: rem(40);
		height: rem(40);
	}

	.product-name {
		@include ellipsisOneline;
	}

	.divider {
		height: rem(3);
		border-top: rem(3) solid black;
	}

	// Override z-index for confirm modal
	::v-deep .modal-confirm-cancel-order {
		.modal {
			z-index: $z-index-modal + 2;
		}

		.modal-backdrop {
			z-index: $z-index-modal + 1;
		}
	}
</style>
