var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-discount-container"},[_c('CModal',{staticClass:"modal-discount-details",attrs:{"show":_vm.isShow,"close-on-select":false,"centered":""},on:{"update:show":function($event){_vm.isShow=$event}},scopedSlots:_vm._u([{key:"header-wrapper",fn:function(){return [_c('ModalHeaderWrapper',{attrs:{"title":_vm.title},on:{"onClose":_vm.close}})]},proxy:true},{key:"body-wrapper",fn:function(){return [(_vm.description)?_c('div',{staticClass:"discount-description typo-body-2 p-3",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),_c('BaseTable',{staticClass:"table-custom",attrs:{"fields":_vm.fields,"items":_vm.data},scopedSlots:_vm._u([{key:"path",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"path"},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"postcode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"path"},[_vm._v(" "+_vm._s(item)+" ")])]}},{key:"product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"product-image",attrs:{"src":item.thumbnail}}),_c('div',[_c('div',{staticClass:"typo-body-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"typo-body-2 color-black-45"},[_vm._v(" SKU: "+_vm._s(item.sku)+" ")])])])]}},{key:"discount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"discount"},[_vm._v(" - "+_vm._s(item)+" ")])]}}])})]},proxy:true},{key:"footer",fn:function(){return [_c('h6',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.totalAmountTitle)+" ")]),_c('h6',{staticClass:"total-amount",class:{ discount: _vm.type === 'promotion' || 'flashSale' }},[_vm._v(" "+_vm._s(_vm.totalAmount)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }