<template>
	<div>
		<BaseModalConfirm
			ref="modal-confirm-send-email"
			title="Confirm to send email?"
			:description="emailDescription"
			@onConfirm="handleConfirmSendEmail"
		/>
		<BaseModalLoading ref="modal-loading" modal-title="Sending" />
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import { ORDER_STATUS } from '../enums/orders';
import { SHIPPING_STATUS, SHIPPING_METHOD_LABELS } from '../enums/shippings';
import { PAYMENT_STATUS } from '../enums/payments';
import { sendOrderEmailAPI } from '../services/api/orders.api';

export default {
	name: 'OrderDetailEmail',
	components: {
		BaseModalConfirm,
	},
	props: {
		order: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		// Order Status
		orderStatus() {
			return this.order?.orderStatus ?? null;
		},
		isCreated() {
			return this.orderStatus === ORDER_STATUS.CREATED;
		},
		isPreparing() {
			return this.orderStatus === ORDER_STATUS.PREPARING;
		},
		isShipped() {
			return this.orderStatus === ORDER_STATUS.SHIPPED;
		},
		isPartiallyShipped() {
			return this.orderStatus === ORDER_STATUS.PARTIALLY_SHIPPED;
		},
		isReadyToPickup() {
			return this.orderStatus === ORDER_STATUS.READY_TO_PICK_UP;
		},
		isCompleted() {
			return this.orderStatus === ORDER_STATUS.COMPLETED;
		},
		isCanceled() {
			return this.orderStatus === ORDER_STATUS.CANCELED;
		},

		// Payment Status
		paymentStatus() {
			return this.order?.payment?.status ?? null;
		},
		isPaid() {
			return this.paymentStatus === PAYMENT_STATUS.PAID;
		},

		// Shipment Status
		shipmentStatus() {
			return this.order?.shipping?.status ?? null;
		},
		isDelivered() {
			return this.shipmentStatus === SHIPPING_STATUS.DELIVERED;
		},

		// Shipping Method
		shippingMethodName() {
			return this.order?.shipping?.shippingMethodName ?? null;
		},
		isPickupAtStore() {
			return this.shippingMethodName === SHIPPING_METHOD_LABELS.PICKUP_AT_STORE;
		},
		isCollectInOneHour() {
			return this.shippingMethodName === SHIPPING_METHOD_LABELS.COLLECT_IN_ONE_HOUR;
		},

		emailDescription() {
			const orderConfirmedMessage = '"Order confirmed" email will send to customer immediately.';
			const orderPlacedMessage = '"Order placed" email will send to customer immediately.';
			const readyToPickUpMessage = '"Ready to pick up" email will send to customer immediately.';
			const orderShippedMessage = '"Order shipped" email will send the information of shipment status is "Shipped" to customer immediately, separate by 1 shipment: 1 email.';
			const orderPickedUpMessage = '"Order picked up" email will send to customer immediately.';
			const orderDeliveredMessage = '"Order delivered" email will send to customer immediately.';
			const orderCanceledMessage = '"Order canceled" email will send to customer immediately.';
			const contactCustomersDirectlyMessage = 'you have to contact our customers directly and take care of them until the refund process is completed.';

			// Created
			if (this.isCreated) {
				if (this.isPaid) {
					return orderConfirmedMessage;
				}

				return orderPlacedMessage;
			}

			// Preparing
			if (this.isPreparing && this.isPaid) {
				return orderConfirmedMessage;
			}

			// Ready to pick up
			if (this.isReadyToPickup && (this.isPickupAtStore || this.isCollectInOneHour)) {
				return readyToPickUpMessage;
			}

			// Shipped & PartiallyShipped
			if ((this.isShipped || this.isPartiallyShipped) && !this.isPickupAtStore) {
				return orderShippedMessage;
			}

			// Completed
			if (this.isCompleted) {
				if (this.isPickupAtStore || this.isCollectInOneHour) {
					return orderPickedUpMessage;
				}

				return orderDeliveredMessage;
			}

			// Canceled
			if (this.isCanceled) {
				if (this.isPaid) {
					return `${orderCanceledMessage} ${contactCustomersDirectlyMessage}`;
				}

				return orderCanceledMessage;
			}

			return null;
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),
		async handleConfirmSendEmail() {
			const orderId = this.$route.params.id;

			this.$refs['modal-loading'].open();
			try {
				await sendOrderEmailAPI(orderId);

				this.showToast({
					content: 'Your message has been sent.',
					header: this.$t('global.successMessageTitle'),
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: this.$t('global.errorMessageTitleSend'),
					type: 'danger',
				});
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
	},
};
</script>
