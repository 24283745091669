<template>
	<div class="modal-order-address-container">
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			class="modal-order-address"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="modalTitle"
					@onClose="close"
				/>
			</template>
			<template #default>
				<div class="flex-fill">
					<CRow>
						<CCol>
							<h6 class="mb-3">
								Contact information
							</h6>
						</CCol>
					</CRow>
					<CRow>
						<CCol md="6">
							<CInput
								v-model.trim="$v.firstNameValue.$model"
								:is-valid="!$v.firstNameValue.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`First name${isRequired ? '*' : ''}`"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="$v.lastNameValue.$model"
								:is-valid="!$v.lastNameValue.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`Last name${isRequired ? '*' : ''}`"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="$v.phoneNumberValue.$model"
								:is-valid="!$v.phoneNumberValue.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`Phone no.${isRequired ? '*' : ''}`"
							/>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<hr>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<h6 class="mb-3">
								Address information
							</h6>
						</CCol>
					</CRow>
					<template v-if="hasTaxInfo">
						<CRow>
							<CCol>
								<h6>Tax address type</h6>
							</CCol>
						</CRow>
						<CRow class="mb-3">
							<CCol>
								<BaseButtonGroup
									:value="taxType"
									:list="ORDER_DETAIL_TAX_LIST"
									@input="(value) => $emit('update:taxType', value)"
								/>
							</CCol>
						</CRow>
						<CRow v-if="taxType === ORDER_DETAIL_TAX_TYPE.PERSONAL">
							<CCol md="6">
								<CInput
									:value="taxId"
									label="ID card no. / Passport no."
									@input="(value) => $emit('update:taxId', value)"
								/>
							</CCol>
							<CCol md="6">
								<CInput
									:value="taxEmail"
									label="Email address"
									@input="(value) => $emit('update:taxEmail', value)"
								/>
							</CCol>
						</CRow>
						<CRow v-if="taxType === ORDER_DETAIL_TAX_TYPE.COMPANY">
							<CCol md="12">
								<CInput
									:value="companyName"
									label="Company name"
									@input="(value) => $emit('update:companyName', value)"
								/>
							</CCol>
							<CCol md="6">
								<CInput
									:value="taxId"
									label="Tax ID"
									@input="(value) => $emit('update:taxId', value)"
								/>
							</CCol>
							<CCol md="6">
								<CInput
									:value="taxEmail"
									label="Email address"
									@input="(value) => $emit('update:taxEmail', value)"
								/>
							</CCol>
							<CCol md="6">
								<CInput
									:value="branchName"
									label="Branch name"
									@input="(value) => $emit('update:branchName', value)"
								/>
							</CCol>
							<CCol md="6">
								<CInput
									:value="branchId"
									label="Branch ID"
									@input="(value) => $emit('update:branchId', value)"
								/>
							</CCol>
						</CRow>
					</template>
					<CRow>
						<CCol md="12">
							<CInput
								v-model.trim="$v.address1Value.$model"
								:is-valid="!$v.address1Value.$error && null"
								:invalid-feedback="address1ErrorMessage"
								:label="`Address line 1${isRequired ? '*' : ''}`"
								placeholder="Address no., Village/Bldg., Soi, Road"
							/>
						</CCol>
						<CCol md="12">
							<CInput
								v-model.trim="$v.address2Value.$model"
								:is-valid="!$v.address2Value.$error && null"
								:invalid-feedback="$t('global.error.maximumLength', {field: 'Address', number: 100})"
								label="Address line 2"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="$v.postcodeValue.$model"
								:is-valid="!$v.postcodeValue.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`Postcode${isRequired ? '*' : ''}`"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="$v.provinceValue.$model"
								:is-valid="!$v.provinceValue.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`Province${isRequired ? '*' : ''}`"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="$v.districtValue.$model"
								:is-valid="!$v.districtValue.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`District${isRequired ? '*' : ''}`"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="$v.subdistrictValue.$model"
								:is-valid="!$v.subdistrictValue.$error && null"
								:invalid-feedback="$t('global.error.required')"
								:label="`Sub district${isRequired ? '*' : ''}`"
							/>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<hr>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<CTextarea
								:value="deliveryDetail"
								label="Delivery detail"
								rows="4"
								@input="(value) => $emit('update:deliveryDetail', value)"
							/>
						</CCol>
					</CRow>
				</div>
			</template>
			<template #footer>
				<CButton
					color="tertiary"
					class="transparent mr-3"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					color="primary"
					class="transparent"
					:disabled="edit.isUpdating"
					@click="handleSubmit"
				>
					Done
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import { scrollToTop } from '../assets/js/helpers';
import { ORDER_DETAIL_TAX_LIST, ORDER_DETAIL_TAX_TYPE } from '../enums/orders';

export default {
	name: 'ModalOrderAddress',
	components: {
		ModalHeaderWrapper,
	},
	validations() {
		const isRequired = this.isRequired ? {
			required,
		} : {};

		return {
			firstNameValue: isRequired,
			lastNameValue: isRequired,
			phoneNumberValue: isRequired,
			address1Value: {
				...isRequired,
				maxLength: maxLength(100),
			},
			address2Value: {
				maxLength: maxLength(100),
			},
			postcodeValue: isRequired,
			provinceValue: isRequired,
			districtValue: isRequired,
			subdistrictValue: isRequired,
		};
	},
	props: {
		hasTaxInfo: {
			type: Boolean,
			default: false,
		},
		addressId: {
			type: Number,
			default: null,
		},
		// Contact information
		firstName: {
			type: String,
			default: null,
		},
		lastName: {
			type: String,
			default: null,
		},
		phoneNumber: {
			type: String,
			default: null,
		},
		// Tax information
		taxType: {
			type: String,
			default: ORDER_DETAIL_TAX_TYPE.PERSONAL,
			validator(value) {
				return [
					ORDER_DETAIL_TAX_TYPE.PERSONAL,
					ORDER_DETAIL_TAX_TYPE.COMPANY,
				].indexOf(value) !== -1;
			},
		},
		companyName: {
			type: String,
			default: null,
		},
		taxId: {
			type: String,
			default: null,
		},
		taxEmail: {
			type: String,
			default: null,
		},
		branchName: {
			type: String,
			default: null,
		},
		branchId: {
			type: String,
			default: null,
		},
		// Address information
		address1: {
			type: String,
			default: null,
		},
		address2: {
			type: String,
			default: null,
		},
		postcode: {
			type: String,
			default: null,
		},
		province: {
			type: String,
			default: null,
		},
		district: {
			type: String,
			default: null,
		},
		subdistrict: {
			type: String,
			default: null,
		},
		deliveryDetail: {
			type: String,
			default: null,
		},
		// #endregion
		addressType: {
			type: String,
			default: null,
		},
		orderId: {
			type: Number,
			default: null,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	computed: {
		...mapState('orderAddresses', {
			edit: 'edit',
		}),
		ORDER_DETAIL_TAX_LIST: () => ORDER_DETAIL_TAX_LIST,
		ORDER_DETAIL_TAX_TYPE: () => ORDER_DETAIL_TAX_TYPE,
		modalTitle() {
			const type = this.addressType
				? `${this.addressType.charAt(0).toUpperCase()}${this.addressType.slice(1)}`
				: '';

			return `${type} address`;
		},

		firstNameValue: {
			get() {
				return this.firstName;
			},
			set(value) {
				this.$emit('update:firstName', value);
			},
		},
		lastNameValue: {
			get() {
				return this.lastName;
			},
			set(value) {
				this.$emit('update:lastName', value);
			},
		},
		phoneNumberValue: {
			get() {
				return this.phoneNumber;
			},
			set(value) {
				this.$emit('update:phoneNumber', value);
			},
		},
		address1Value: {
			get() {
				return this.address1;
			},
			set(value) {
				this.$emit('update:address1', value);
			},
		},
		address2Value: {
			get() {
				return this.address2;
			},
			set(value) {
				this.$emit('update:address2', value);
			},
		},
		postcodeValue: {
			get() {
				return this.postcode;
			},
			set(value) {
				this.$emit('update:postcode', value);
			},
		},
		provinceValue: {
			get() {
				return this.province;
			},
			set(value) {
				this.$emit('update:province', value);
			},
		},
		districtValue: {
			get() {
				return this.district;
			},
			set(value) {
				this.$emit('update:district', value);
			},
		},
		subdistrictValue: {
			get() {
				return this.subdistrict;
			},
			set(value) {
				this.$emit('update:subdistrict', value);
			},
		},
		address1ErrorMessage() {
			if (this.isRequired) {
				if (!this.$v.address1Value.required) {
					return this.$t('global.error.required');
				}
			}

			if (!this.$v.address1Value.maxLength) {
				return this.$t('global.error.maximumLength', { field: 'Address', number: 100 });
			}

			return null;
		},
	},
	methods: {
		...mapActions({
			updateOrderAddress: 'orderAddresses/updateOrderAddress',
			createOrderAddress: 'orderAddresses/createOrderAddress',
		}),

		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
		async handleSubmit() {
			this.$v.$touch();

			if (this.isRequired && this.$v.$invalid) {
				scrollToTop('.modal-order-address > .modal');
				return;
			}

			const params = {
				// Contact Info
				first_name: this.firstName,
				last_name: this.lastName,
				phone_number: this.phoneNumber,

				// Tax Info
				company_name: this.companyName,
				tax_id: this.taxId,
				tax_email: this.taxEmail,
				tax_branch_code: this.branchId,
				tax_branch_name: this.branchName,
				is_juristic: this.taxType === ORDER_DETAIL_TAX_TYPE.COMPANY,

				// Address Info
				address_1: this.address1,
				address_2: this.address2,
				postcode: this.postcode,
				province: this.province,
				district: this.district,
				subdistrict: this.subdistrict,
				detail: this.deliveryDetail,
				// #endregion

				type: this.addressType,
			};

			try {
				// Update if address exists
				if (this.addressId) {
					await this.updateOrderAddress({
						id: this.addressId,
						orderId: this.orderId,
						params,
					});
				} else {
					await this.createOrderAddress({
						orderId: this.orderId,
						params,
					});
				}
				this.close();
			} catch (error) {
				// console.error(error);
			}
		},
	},
};
</script>
