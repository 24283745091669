<template>
	<div>
		<div class="mb-3">
			<CButton
				v-if="showMarkAsPaidButton"
				:disabled="isUpdating"
				class="btn-mark-as-paid ml-auto m-0 p-0 float-right"
				@click="$refs['modal-confirm-paid'].open()"
			>
				Mark as paid
			</CButton>
			<h3 class="typo-h6 d-flex">
				{{ title }}
			</h3>
			<div>
				<label class="payment-info-label mb-0">
					Payment method: <span class="payment-info-value">{{ payment.paymentMethodName }}</span>
				</label>
				<label class="payment-info-label mb-0">
					Expire date:
					<span class="payment-info-value" :class="expireDateClass">
						{{ payment.expiredAt | datetimeFormat }}
						<a
							v-if="isShowEditPaymentExpire"
							class="ml-2"
							href="#"
							@click="$refs['modal-edit-payment-expire'].open()"
						>
							<CIcon class="icon-left cil-pencil" name="cil-pencil" /> Edit
						</a>
					</span>
				</label>
				<label v-if="showBinCampaignCode && payment.binCampaignCode" class="payment-info-label mb-0">
					BIN campaign code: <span class="payment-info-value">{{ payment.binCampaignCode }}</span>
				</label>
			</div>
		</div>
		<template v-if="showTransactionInfo">
			<div>
				<h3 class="typo-h6">
					Transaction info
				</h3>
				<div v-if="payment.paidDate && payment.paidAmount">
					<label class="payment-info-label mb-0">
						Paid date: <span class="payment-info-value">{{ payment.paidDate | datetimeFormat }}</span>
					</label>
					<label class="payment-info-label mb-0">
						Paid amount: <span class="payment-info-value">{{ payment.paidAmount }}</span>
					</label>
					<label v-if="payment.redemptionAmount" class="payment-info-label mb-0">
						Redemption amount: <span class="payment-info-value">{{ payment.redemptionAmount }}</span>
					</label>
					<label v-if="payment.redemptionPoint" class="payment-info-label mb-0">
						Redemption point: <span class="payment-info-value">{{ payment.redemptionPoint }}</span>
					</label>
					<label v-if="isInstallmentPlan" class="payment-info-label mb-0">
						Installment plan:&nbsp;
						<span class="payment-info-value">
							<template v-if="payment.installment.interestRate !== null">
								{{ payment.installment.interestRate }}%&nbsp;
							</template>
							{{ payment.installment.period }} months
						</span>
					</label>
					<label v-if="payment.cardNumberLastDigits" class="payment-info-label mb-0">
						Card no: <span class="payment-info-value">{{ transformCardNumber(payment.cardNumberLastDigits) }}</span>
					</label>
					<label v-if="payment.cardType" class="payment-info-label mb-0">
						Card type: <span class="payment-info-value">{{ payment.cardType }}</span>
					</label>
					<label v-if="payment.cardProvider" class="payment-info-label mb-0">
						Card provider: <span class="payment-info-value">{{ payment.cardProvider }}</span>
					</label>
					<label v-if="payment.gateway" class="payment-info-label mb-0">
						Gateway: <span class="payment-info-value">{{ payment.gateway }}</span>
					</label>
					<label v-if="payment.point" class="payment-info-label mb-0">
						Point amount: <span class="payment-info-value"> {{ payment.point }} points </span>
					</label>
					<label v-if="agentName" class="payment-info-label mb-0">
						Agent: <span class="payment-info-value">{{ agentName }}</span>
					</label>
					<label v-if="payment.transactionMethod" class="payment-info-label mb-0">
						Method: <span class="payment-info-value">{{ payment.transactionMethod }}</span>
					</label>
				</div>
				<div v-else class="typo-body-2 color-black-45">
					-
				</div>
			</div>
		</template>
		<ModalOrderDetailMarkAsPaid
			ref="modal-confirm-paid"
			:is-submitting="isUpdating"
			@onConfirm="handleMarkAsPaid"
		/>
		<ModalEditPaymentExpire
			ref="modal-edit-payment-expire"
			title="Set payment expire date and time"
			:is-submitting="isUpdatingPaymentExpire"
			@onConfirm="handlePaymentExpire"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalOrderDetailMarkAsPaid from '@/components/ModalOrderDetailMarkAsPaid.vue';
import ModalEditPaymentExpire from '@/components/ModalEditPaymentExpire.vue';
import { PAYMENT_STATUS, PAYMENT_GATEWAY } from '../enums/payments';
import { ORDER_STATUS } from '../enums/orders';
import { transformCardNumber, convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'OrderDetailPaymentInfo',
	components: {
		ModalOrderDetailMarkAsPaid,
		ModalEditPaymentExpire,
	},
	props: {
		title: {
			type: String,
			default: 'Payment info',
		},
		payment: {
			type: Object,
			default: () => ({}),
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		isPartiallyPaid: {
			type: Boolean,
			default: false,
		},
		showMarkAsPaidButton: {
			type: Boolean,
			default: false,
		},
		remainingPayment: {
			type: Boolean,
			default: false,
		},
		orderStatus: {
			type: String,
			default: null,
		},
		showBinCampaignCode: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isUpdatingPaymentExpire: false,
		};
	},
	computed: {
		...mapGetters({
			username: 'auth/getUsername',
		}),
		isInstallmentPlan() {
			return this.payment.installment && this.payment.installment?.period;
		},
		expireDateClass() {
			return this.isPaymentStatusExpired ? 'color-alert' : '';
		},
		isPaymentStatusPaid() {
			return this.payment.status === PAYMENT_STATUS.PAID;
		},
		isPaymentStatusFailed() {
			return this.payment.status === PAYMENT_STATUS.FAILED;
		},
		isShowEditPaymentExpire() {
			const paymentMethods = [
				PAYMENT_STATUS.PARTIALLY_PAID,
				PAYMENT_STATUS.PENDING,
				PAYMENT_STATUS.PAID,
				PAYMENT_STATUS.EXPIRED,
			];

			const orderStatuses = [
				ORDER_STATUS.CANCELED,
				ORDER_STATUS.EXPIRED,
			];

			return !paymentMethods.includes(this.payment.status)
				&& !orderStatuses.includes(this.orderStatus)
				&& !this.isPartiallyPaid;
		},
		showTransactionInfo() {
			return this.isPaymentStatusPaid || this.isPaymentStatusFailed;
		},
		agentName() {
			if (!this.payment.agent) {
				return null;
			} else if (this.payment.agent === PAYMENT_GATEWAY.KTC_FLEXI) {
				return 'ผ่อนชำระด้วย KTC';
			} else if (this.payment.agent === PAYMENT_GATEWAY.KTC_AI) {
				return 'ผ่อนชำระด้วยบัตรเงินสด KTC';
			}

			return this.payment.agent;
		},
	},
	methods: {
		...mapActions({
			updateOrder: 'orders/updateOrder',
			updatePaymentExpire: 'orders/updatePaymentExpire',
			createOrderRemark: 'orders/createOrderRemark',
		}),

		transformCardNumber,

		async handleMarkAsPaid(message) {
			const orderId = this.payment.orderId;

			try {
				// Update remark, stamp MARK AS PAID
				const remarkTxt = `MARK AS PAID by ${this.username}\n${message}`;
				await this.createOrderRemark({ id: orderId, params: { message: remarkTxt }, forceReload: false });

				const params = this.remainingPayment
					? { remaining_payment_status: PAYMENT_STATUS.PAID }
					: { payment_status: PAYMENT_STATUS.PAID };

				await this.updateOrder({ id: orderId, params });
			} catch {
				// do nothing
			}
		},

		async handlePaymentExpire({ expireDate, expireTime }) {
			const orderId = this.payment.orderId;
			const expireDateTime = convertDateTimeToUTC(expireDate, expireTime);

			try {
				this.isUpdatingPaymentExpire = true;
				const paramPaymentExpireKey = this.remainingPayment ? 'remaining_payment_expired_at' : 'payment_expired_at';
				const params = {
					[paramPaymentExpireKey]: expireDateTime,
				};

				await this.updatePaymentExpire({ id: orderId, params });
			} finally {
				this.isUpdatingPaymentExpire = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-mark-as-paid {
		color: $color-hypertext;
		font-size: rem($font-size-body-2);
		font-weight: $font-weight-normal;

		&:hover {
			text-decoration: underline;
		}
	}

	.payment-info {
		// .payment-info-label
		&-label {
			@include typo-label;

			display: block;
			color: $color-black-45;
		}

		// .payment-info-value
		&-value {
			@include typo-body-2;

			color: $color-black;
			font-weight: 400;
		}
	}
</style>
