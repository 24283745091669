<template>
	<div class="modal-order-mark-as-paid-container">
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			class="modal-order-mark-as-paid"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Mark this payment as paid?"
					@onClose="close"
				/>
			</template>
			<template #default>
				<p>Changing this payment status the status will be changed to 'Paid' and it can't be undone. Please type your user ID to confirm action.</p>
				<CInput
					v-model.trim="message"
					placeholder="Please type your user ID to confirm"
				/>
			</template>
			<template #footer>
				<CButton
					color="tertiary"
					class="transparent mr-3"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					color="primary"
					class="transparent"
					:disabled="!message || isSubmitting"
					@click="handleSubmit"
				>
					{{ isSubmitting ? 'Submitting...' : 'Confirm' }}
				</CButton>
			</template>
		</CModal>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'ModalOrderDetailMarkAsPaid',
	components: {
		ModalHeaderWrapper,
	},
	props: {
		isSubmitting: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isShow: false,
			message: null,
		};
	},
	methods: {
		open() {
			this.isShow = true;
			this.message = null;
		},
		close() {
			this.isShow = false;
		},
		async handleSubmit() {
			this.$emit('onConfirm', this.message);
		},
	},
};
</script>
