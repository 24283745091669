<template>
	<div class="modal-discount-container">
		<CModal
			:show.sync="isShow"
			:close-on-select="false"
			class="modal-discount-details"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="close"
				/>
			</template>
			<template #body-wrapper>
				<!-- eslint-disable-next-line vue/no-v-html -->
				<div v-if="description" class="discount-description typo-body-2 p-3" v-html="description"></div>
				<BaseTable
					:fields="fields"
					:items="data"
					class="table-custom"
				>
					<template #path="{ item }">
						<div class="path">
							{{ item }}
						</div>
					</template>

					<template #postcode="{ item }">
						<div class="path">
							{{ item }}
						</div>
					</template>

					<template #product="{ item }">
						<div class="d-flex">
							<img class="product-image" :src="item.thumbnail">
							<div>
								<div class="typo-body-2">
									{{ item.name }}
								</div>
								<div class="typo-body-2 color-black-45">
									SKU: {{ item.sku }}
								</div>
							</div>
						</div>
					</template>
					<template #discount="{ item }">
						<div class="discount">
							- {{ item }}
						</div>
					</template>
				</BaseTable>
			</template>
			<template #footer>
				<h6 class="mr-4">
					{{ totalAmountTitle }}
				</h6>
				<h6 class="total-amount" :class="{ discount: type === 'promotion' || 'flashSale' }">
					{{ totalAmount }}
				</h6>
			</template>
		</CModal>
	</div>
</template>
<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'ModalOrderSummaryDetail',
	components: {
		ModalHeaderWrapper,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		fields: {
			type: Array,
			default: () => [],
		},
		data: {
			type: Array,
			default: () => [],
		},
		totalAmount: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	computed: {
		totalAmountTitle() {
			let title;
			switch (this.type) {
				case 'promotion':
					title = 'Total discount';
					break;

				case 'flashSale':
					title = 'Total flash sale discount';
					break;

				case 'fee':
					title = 'Total additional fee';
					break;

				default:
					break;
			}
			return title;
		},
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .table-custom {
		margin: 0;

		.table {
			margin-bottom: 0;
		}

		.table thead > tr > th {
			background-color: $color-gray-100;
		}

		.table thead > tr > th:first-child,
		.table tbody > tr > td:first-child {
			padding-left: rem(16);
		}

		.table thead > tr > th:last-child,
		.table tbody > tr > td:last-child {
			padding-right: rem(16);
		}
	}

	.product-image {
		width: rem(40);
		height: rem(40);
		border: rem(1) solid $color-gray-300;
		border-radius: rem(4);
		padding: rem(2);
		margin-right: rem(16);
	}

	.path {
		display: inline-block;
		border-radius: rem(4);
		background-color: $color-gray-300;
		padding: 0 rem(6);
		margin-bottom: rem(8);
		margin-right: rem(8);
	}

	.total-amount {
		min-width: rem(120);
		text-align: right;
	}

	.discount {
		color: $color-alert;
	}

	::v-deep .modal-discount-details {
		.modal-dialog {
			max-width: rem(600);
		}
	}
</style>
