<template>
	<div class="border-left pl-4">
		<a
			v-if="$listeners.onEdit"
			class="btn-edit-address btn-link float-right"
			href="#"
			@click="handleEdit"
		>
			<CIcon class="icon-left cil-pencil" name="cil-pencil" /> Edit
		</a>
		<h3 class="typo-h6">
			{{ title }}
		</h3>
		<template v-if="address">
			<p class="typo-body-2 mb-0" data-test-id="address-personal-info">
				{{ firstName }} {{ lastName }}{{ phoneNumber }}
			</p>
			<p v-if="address.taxEmail" class="address-detail mb-0" data-test-id="address-tax-email-info">
				Email: {{ address.taxEmail || '-' }}
			</p>
			<template v-if="address.isJuristic">
				<p v-if="address.companyName" class="address-detail mb-0">
					Company name: {{ address.companyName }}
				</p>
				<p v-if="address.taxId" class="address-detail mb-0">
					Tax ID: {{ address.taxId }}
				</p>
				<p v-if="address.taxBranchName" class="address-detail mb-0">
					Branch name: {{ address.taxBranchName }}
				</p>
				<p v-if="address.taxBranchCode" class="address-detail mb-0">
					Branch ID: {{ address.taxBranchCode }}
				</p>
			</template>
			<template else>
				<p v-if="address.nationalId" class="address-detail mb-0">
					ID card no./Passport no: {{ address.nationalId }}
				</p>
			</template>
			<p class="address-detail">
				{{ addressDetail }}
			</p>
		</template>
		<template v-else>
			<p class="address-detail" data-test-id="empty-address">
				-
			</p>
		</template>
		<template v-if="deliveryDetails">
			<p class="typo-body-2 mb-0">
				Delivery detail
			</p>
			<p class="address-detail">
				{{ deliveryDetails }}
			</p>
		</template>
	</div>
</template>

<script>
export default {
	name: 'OrderAddressDetails',
	props: {
		address: {
			type: Object,
			default: () => ({}),
		},
		title: {
			type: String,
			default: 'Address',
		},
	},
	computed: {
		firstName() {
			return this.address.firstName || '';
		},
		lastName() {
			return this.address.lastName || '';
		},
		deliveryDetails() {
			return this.address && this.address.detail
				? this.address.detail
				: null;
		},
		phoneNumber() {
			return this.address.phoneNumber
				? ` (${this.address.phoneNumber})`
				: '';
		},
		addressDetail() {
			const details = [];

			if (this.address) {
				if (this.address.address1) {
					details.push(this.address.address1);
				}
				if (this.address.address2) {
					details.push(this.address.address2);
				}
				if (this.address.subdistrict) {
					details.push(this.address.subdistrict);
				}
				if (this.address.district) {
					details.push(this.address.district);
				}
				if (this.address.province) {
					details.push(this.address.province);
				}
				if (this.address.postcode) {
					details.push(this.address.postcode);
				}
			}

			return details.length
				? details.join(', ')
				: '-';
		},
	},
	methods: {
		handleEdit() {
			this.$emit('onEdit');
		},
	},
};
</script>

<style lang="scss" scoped>
	.address-detail {
		@include typo-body-2;

		color: $color-black-45;
	}

	/**
	* Print
	*/
	@media print {
		.btn-edit-address {
			display: none;
		}
	}
</style>
