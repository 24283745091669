<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		centered
		size="lg"
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				title="Create shipment"
				@onClose="close"
			/>
		</template>
		<template #body-wrapper>
			<CRow
				:gutters="false"
				class="header p-3"
			>
				<CCol md="8">
					<div class="d-flex">
						<CInputCheckbox
							:checked.sync="isSelectAll"
							custom
							class="select-all-input"
							@update:checked="handleSelectAll"
						/>
						<span class="label">
							Product
						</span>
					</div>
				</CCol>
				<CCol md="4">
					<CRow
						:gutters="false"
					>
						<CCol md="4">
							<div class="label text-center">
								Qty in order
							</div>
						</CCol>
						<CCol md="4">
							<div class="label text-center">
								Not packed
							</div>
						</CCol>
						<CCol md="4">
							<div class="label text-center">
								Qty Packed
							</div>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
			<CRow
				v-for="vItem in $v.items.$each.$iter"
				:key="vItem.id"
				:gutters="false"
				align-vertical="start"
				class="p-3 border-top"
			>
				<CCol md="8">
					<div class="d-flex align-items-center">
						<div>
							<CInputCheckbox
								:disabled="!vItem.$model.notPacked"
								:checked.sync="vItem.$model.isSelected"
								custom
							/>
						</div>
						<div class="mr-3">
							<img
								:src="vItem.$model.product.thumbnail"
								class="product-image border rounded-lg"
							>
						</div>
						<div class="d-flex flex-column flex-fill">
							<div class="typo-body-2">
								{{ vItem.$model.product.name }}
							</div>
							<div class="typo-body-2 color-black-45">
								{{ vItem.$model.product.sku }}
							</div>
							<CBadge v-if="vItem.$model.product.type === ORDER_ITEM_TYPE.FREEBIE" class="badge badge-freebie mt-2">
								Freebie
							</CBadge>
						</div>
					</div>
				</CCol>
				<CCol md="4">
					<CRow
						:gutters="false"
						align-vertical="start"
					>
						<CCol md="4">
							<div class="text-center py-2">
								{{ vItem.$model.quantity }}
							</div>
						</CCol>
						<CCol md="4">
							<div class="text-center py-2">
								{{ notPackedAmount(vItem.$model.notPacked, vItem.$model.quantityPacked) }}
							</div>
						</CCol>
						<CCol md="4">
							<BaseInputNumber
								v-if="vItem.$model.isSelected"
								v-model="vItem.quantityPacked.$model"
								:is-valid="!vItem.$error"
								:invalid-feedback="errorsQuantity(vItem.quantityPacked)"
								text-align="center"
								@input="vItem.$touch()"
							/>
							<div
								v-else
								class="text-center py-2"
							>
								{{ vItem.$model.quantityPacked || 0 }}
							</div>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<hr>
				</CCol>
			</CRow>
			<CRow
				:gutters="false"
				class="px-3"
			>
				<CCol md="8" />
				<CCol md="4">
					<CRow
						:gutters="false"
						class="total"
					>
						<CCol md="8">
							<div class="label">
								Total packed item(s)
							</div>
						</CCol>
						<CCol md="4">
							<div class="label text-center">
								{{ totalPackedItems }}
							</div>
						</CCol>
					</CRow>
					<hr class="divider">
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<hr>
				</CCol>
			</CRow>
			<CRow
				:gutters="false"
				class="px-3 pb-3"
			>
				<CCol md="8">
					<div class="label">
						Warehouse
					</div>
					<div class="typo-body-2 color-gray-500">
						Select warehouse for courier to pick up
					</div>
				</CCol>
				<CCol md="4">
					<BaseDropDown
						v-model="$v.selectedBranch.$model"
						:options="warehouseOptions"
						:is-valid="!$v.selectedBranch.$error"
						:invalid-feedback="$t('global.error.required')"
						label="name"
					/>
				</CCol>
			</CRow>
		</template>
		<template #footer>
			<div class="label flex-fill">
				{{ totalPackedItems }} Selected
			</div>
			<CButton
				color="tertiary"
				class="mr-3"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				color="primary"
				:disabled="shipment.isCreating || $v.$invalid || totalPackedItems <= 0 || !isSomeSelected"
				@click="handleCreate"
			>
				{{ shipment.isCreating ? 'Creating' : 'Create' }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, requiredIf, helpers } from 'vuelidate/lib/validators';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import { ECOMMERCE_WAREHOUSE_ID } from '../enums/warehouses';
import { ORDER_ITEM_TYPE } from '../enums/orders';
import { cloneDeep } from '../assets/js/helpers';

export default {
	name: 'ModalShipmentCreate',
	components: {
		ModalHeaderWrapper,
	},
	validations: {
		selectedBranch: {
			required,
		},
		items: {
			$each: {
				quantityPacked: {
					required: requiredIf('isSelected'),
					minValue: (value, vm) => {
						if (!vm.isSelected) {
							return true;
						}
						return Number(value) >= 1;
					},
					maxValue: (value, vm) => {
						return helpers.withParams(
							{ type: 'maxValue', value: vm.notPacked },
							(innerValue, innerVm) => {
								if (!innerVm.isSelected) {
									return true;
								}
								return Number(innerValue) <= innerVm.notPacked;
							},
						)(value, vm);
					},
				},
			},
		},
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			isSelectAll: false,
			selectedBranch: null,
			originalSelected: [],
			ORDER_ITEM_TYPE,
		};
	},
	computed: {
		...mapState('shipments', {
			shipment: 'shipment',
		}),
		...mapGetters({
			warehouseOptions: 'warehouses/warehouseOptions',
		}),
		totalPackedItems() {
			return this.items.reduce((acc, item) => acc + Number(item.quantityPacked), 0);
		},
		isSomeSelected() {
			return this.items.some((item) => item.isSelected);
		},
		selectedItems() {
			return this.items.filter((item) => item.isSelected);
		},
		unselectedItems() {
			return this.items.filter((item) => !item.isSelected);
		},
	},
	watch: {
		items: {
			deep: true,
			handler() {
				this.checkSelectionState();
			},
		},
		/**
		 * Set default quantity to be packed when check product item (default is number of product that's not packed)
		 */
		selectedItems(items = []) {
			const itemIndexes = items.map((item) => item.itemIndex);

			itemIndexes.forEach((index) => {
				const itemIndex = this.items.findIndex((item) => item.itemIndex === index);
				this.$set(this.items[itemIndex], 'quantityPacked', this.items[itemIndex].notPacked);
			});
		},
		/**
		 * Reset quantity to be packed to 0 when uncheck product item
		 */
		unselectedItems(items = []) {
			const itemIndexes = items.map((item) => item.itemIndex);

			itemIndexes.forEach((index) => {
				const itemIndex = this.items.findIndex((item) => item.itemIndex === index);
				this.$set(this.items[itemIndex], 'quantityPacked', 0);
			});
		},
	},
	async created() {
		await this.getWarehouseList({
			per_page: 'all',
		});

		this.selectedBranch = this.warehouseOptions.find((warehouse) => warehouse.com7BranchId === ECOMMERCE_WAREHOUSE_ID);
	},
	methods: {
		...mapActions({
			getWarehouseList: 'warehouses/getWarehouseList',
			createShipment: 'shipments/createShipment',
		}),
		open() {
			this.isShow = true;
			this.originalSelected = cloneDeep(this.items);
		},
		close() {
			this.isShow = false;
			this.$emit('update:items', this.originalSelected);
		},
		notPackedAmount(notPackedQuantity, packedQuantity) {
			const amount = notPackedQuantity - packedQuantity;
			return amount < 0
				? 0
				: amount;
		},
		errorsQuantity(v) {
			if (!v.required) {
				return 'Requied field';
			}

			if (!v.minValue) {
				return 'Minimun is 1';
			}

			if (!v.maxValue) {
				return `Maximum is ${v.$params.maxValue.value}`;
			}

			return null;
		},
		handleSelectAll(checked) {
			this.items.forEach((item) => {
				if (item.notPacked) {
					item.isSelected = checked;
				}
			});
		},
		async handleCreate() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const orderId = this.$route.params.id;
			const params = {
				order_id: orderId,
				branch_id: this.selectedBranch.value,
				order_items: this.items
					.filter((item) => item.isSelected)
					.map((item) => {
						return {
							id: item.product.id,
							quantity: item.quantityPacked,
						};
					}),
			};
			await this.createShipment(params);
			this.$router.go(0);
		},
		checkSelectionState() {
			const isEverySelected = this.items.length && this.items.every((item) => item.isSelected);
			const isSomeSelected = this.items.length && this.items.some((item) => item.isSelected);

			if (isEverySelected) {
				this.setSelectedAllState('checked');
				return;
			}

			if (isSomeSelected) {
				this.setSelectedAllState('indeterminated');
				return;
			}

			this.setSelectedAllState('unchecked');
		},
		setSelectedAllState(state) {
			let isChecked = false;
			let isIndeterminate = false;
			switch (state) {
				case 'checked': {
					isChecked = true;
					isIndeterminate = false;
					break;
				}
				case 'unchecked': {
					isChecked = false;
					isIndeterminate = false;
					break;
				}
				case 'indeterminated': {
					isChecked = true;
					isIndeterminate = true;
					break;
				}
				default: break;
			}

			const selectAllEle = this.$el.querySelector('.select-all-input input');
			if (selectAllEle) {
				selectAllEle.checked = isChecked;
				selectAllEle.indeterminate = isIndeterminate;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.header {
		background-color: $color-gray-100;
	}

	.product-image {
		width: rem(40);
		height: rem(40);
	}

	.product-name {
		@include ellipsisOneline;
	}

	.divider {
		height: rem(3);
		border-top: rem(3) solid black;
	}
</style>
