<template>
	<div class="card card-order-detail-shipping custom-card mt-4 mb-0">
		<div class="card-header typo-h5 d-flex align-items-center">
			<span>Shipments</span>
			<CButton
				v-if="showCreateShipmentButton"
				color="secondary"
				class="btn-create-shipment ml-auto"
				@click="handleClickCreateShipment"
			>
				<CIcon name="cil-plus" class="mr-2" /> Create shipment
			</CButton>
		</div>
		<div v-if="isBnnStores" class="card-body">
			<CRow>
				<CCol>
					<h3 class="typo-h6">
						Shipment info
					</h3>
					<div>
						<label class="shipping-info-label mb-0">
							Shipment method: <span class="shipping-info-value">{{ order.shipping.shippingMethodName }}</span>
						</label>
						<label :class="['shipping-info-label', { 'mb-0': showPickupInfo }]">
							Order item: <span class="shipping-info-value">{{ order.quantity }} item(s)</span>
						</label>
						<template v-if="showPickupInfo">
							<label class="shipping-info-label mb-0">
								Pickup branch: <span class="shipping-info-value">{{ order.preOrder.pickup.branchName }}</span>
							</label>
							<label class="shipping-info-label">
								Pickup date: <span class="shipping-info-value">{{ shipmentPickupDateTime }}</span>
							</label>
						</template>
					</div>
				</CCol>
				<CCol>
					<OrderAddressDetails
						title="Shipping address"
						:address="order.shipping.address"
						@onEdit="handleEditShippingAddress"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol>
					<h3 class="typo-h6">
						Shipment order
					</h3>
					<BaseTable
						:fields="ORDER_DETAIL_SHIPMENT_TABLE_FIELDS"
						:items="shipmentDataTable"
						class="table-custom shipping-table"
					>
						<template #no-items-view>
							<div class="empty-table-element">
								<p class="typo-body-1 color-black-45 mb-0">
									No shipment order yet
								</p>
							</div>
						</template>
						<template #shipment="{ item }">
							<CLink
								:to="{
									name: 'ShipmentDetail',
									params: { id: item.id },
								}"
							>
								{{ item.code }}
							</CLink>
						</template>
						<template #status="{ item = '' }">
							<CBadge class="badge-shipping-status" :color="item.color">
								{{ item.title }}
							</CBadge>
						</template>
					</BaseTable>
				</CCol>
			</CRow>
		</div>
	</div>
</template>

<script>
import OrderAddressDetails from '@/components/OrderAddressDetails.vue';
import {
	ORDER_COLOR,
	ORDER_TITLE,
	ORDER_DETAIL_SHIPMENT_TABLE_FIELDS,
	ORDER_DETAIL_ADDRESS_TYPE,
	ORDER_STATUS,
	ORDER_STORE_BNN_ID,
	ORDER_STORE_STUDIO_7_ID,
} from '../enums/orders';
import { SHIPMENT_STATUS } from '../enums/shipments';

export default {
	name: 'OrderDetailShipping',

	components: {
		OrderAddressDetails,
	},
	props: {
		order: {
			type: Object,
			default: null,
		},
		isBnnStores: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			ORDER_DETAIL_SHIPMENT_TABLE_FIELDS,
			ORDER_STORE_BNN_ID,
			ORDER_STORE_STUDIO_7_ID,
		};
	},
	computed: {
		shippingStatus() {
			return {
				title: ORDER_TITLE.SHIPPING[this.order.shipping.status],
				color: ORDER_COLOR.SHIPPING[this.order.shipping.status],
			};
		},
		shipmentDataTable() {
			return this.order && this.order.shipments ? this.order.shipments.map((item) => ({
				id: item.id,
				shipment: {
					id: item.id,
					code: item.code,
				},
				branchId: item.branch.com7BranchId,
				packedItem: item.quantity,
				courier: item.courier,
				trackingNumber: item.trackingNumber,
				status: {
					title: ORDER_TITLE.SHIPPING[item.status],
					color: ORDER_COLOR.SHIPPING[item.status],
				},
			})) : [];
		},
		showCreateShipmentButton() {
			if (!this.order.items || !this.order.shipments) {
				return false;
			}
			const {
				READY_TO_BOOK_LOGISTIC,
				READY_TO_SHIP, SHIPPED,
				DELIVERED,
				BOOKING_FAILED,
				SHIPPING_FAILED,
			} = SHIPMENT_STATUS;

			const totalItemQuantity = this.order.allProducts.reduce((acc, item) => acc + item.quantity, 0);
			const totalPackedQuantity = this.order.shipments
				.filter((shipment) => {
					return [
						READY_TO_BOOK_LOGISTIC,
						READY_TO_SHIP,
						SHIPPED,
						DELIVERED,
						BOOKING_FAILED,
						SHIPPING_FAILED,
					].includes(shipment.status);
				})
				.reduce((acc, shipment) => acc + shipment.quantity, 0);

			const isRemainItemPacked = totalItemQuantity !== totalPackedQuantity;

			return isRemainItemPacked
				&& this.order.orderStatus !== ORDER_STATUS.CREATED
				&& this.order.orderStatus !== ORDER_STATUS.COMPLETED
				&& this.order.orderStatus !== ORDER_STATUS.EXPIRED
				&& this.order.orderStatus !== ORDER_STATUS.CANCELED
				&& this.isBnnStores;
		},
		showPickupInfo() {
			return this.order.preOrder?.pickup !== undefined;
		},
		shipmentPickupDateTime() {
			return `${this.order.preOrder.pickup.date}, ${this.order.preOrder.pickup.startTime} - ${this.order.preOrder.pickup.endTime}`;
		},
	},
	methods: {
		handleEditShippingAddress() {
			this.$emit('onClickEditAddress', {
				address: this.order.shipping.address,
				addressType: ORDER_DETAIL_ADDRESS_TYPE.SHIPPING,
			});
		},
		handleClickCreateShipment() {
			this.$emit('onCreateShipment');
		},
	},
};
</script>

<style lang="scss" scoped>
	.shipping-info {
		// .shipping-info-label
		&-label {
			@include typo-label;

			display: block;
			color: $color-black-45;
		}

		// .shipping-info-value
		&-value {
			@include typo-body-2;

			color: $color-black;
			font-weight: 400;
		}
	}

	.badge-shipping-status {
		width: auto;
		padding: rem(7) rem(8);
		font-size: rem(14);
	}

	.shipping-table {
		.badge-status {
			margin-left: 0;
		}
	}

	::v-deep .shipping-table {
		margin: 0 0 rem(-12);
		overflow: hidden;

		.table {
			margin-bottom: 0;
		}

		thead > tr > th,
		tbody > tr > td {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		thead {
			th {
				padding: rem(12) rem(4);
			}
		}

		tbody {
			tr {
				&:last-child {
					td {
						border-bottom: none;
					}
				}

				td {
					padding: rem(12) rem(4);
					word-break: unset;
				}
			}
		}

		.warehouse {
			&-col, &-cel {
				text-align: center;
				word-break: break-word;
			}
		}

		.packed-item {
			&-col, &-cel {
				text-align: center;
				word-break: break-word;
			}
		}

		.courier {
			&-col, &-cel {
				word-break: break-word;
			}
		}

		// Override column width
		.shipment-col {
			width: 25%;
		}

		.empty-table-element {
			height: auto;
			padding: rem(24) 0;
		}
	}

	/**
	* Print
	*/
	@media print {
		.btn-create-shipment {
			display: none;
		}

		.badge-shipping-status {
			border-color: transparent;
		}

		::v-deep .shipping-table {
			margin-top: rem(16);

			thead > tr > th,
			tbody > tr > td {
				border-bottom: none;

				&:first-child {
					padding-left: rem(16);
				}
			}

			.shipment-cel {
				a {
					color: $color-black-90;
					text-decoration: none;
				}
			}
		}
	}
</style>
